import React, { useState } from 'react'
import { Card, Col, Row } from 'antd'
import { FaRegMoneyBillAlt } from 'react-icons/fa'
import { FiCalendar, FiClock } from 'react-icons/fi'
import moment from 'moment'
import { getAgeRange } from '../../../../functions/getAgeRanges'
import { PencilSquareIcon } from '@heroicons/react/24/outline'
import EditRoboBundler from './editRoboBundler'


interface cardProps {
    handleReFetch: () => void
    theData: any
}





const BundlerCard = ({ theData, handleReFetch }: cardProps) => {


    const [openEdit, setOpenEdit] = useState(false)



    return (
        <>
            <Card className="w-full shadow-lg p-[20px] rounded-[20px]">
                {/* RoboCurriculum */}
                <div className="mb-[20px] flex justify-between items-center">
                    <div>
                        <h2 className="text-[20px] font-semibold">{theData?.name}</h2>
                    </div>

                    <button onClick={() => setOpenEdit(true)}>
                        <PencilSquareIcon className='h-[20px] w-[20px] text-default_blue' />
                    </button>
                </div>

                <hr className='my-[20px]' />

                {/* Elective Courses */}
                <div className="mb-[20px]">
                    <h2 className="text-lg font-semibold mb-2">Elective Courses</h2>
                    <Row gutter={16}>
                        {theData?.electiveCoursesData && theData?.electiveCoursesData.map((course: any, index: number) => (
                            <Col span={6} key={index}>
                                <div className="bg-gray-100 h-[200px] p-[10px] rounded-lg">
                                    <img src={course?.banner} alt='' className="w-full h-24 object-cover rounded-lg" />
                                    <p className="mt-2 text-sm font-[400] line-clamp-4">{course?.name}</p>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </div>

                {/* Addon Courses */}
                <div className="mb-[20px]">
                    <h2 className="text-lg font-semibold mb-2">Addon Courses</h2>
                    <Row gutter={16}>
                        {theData?.peripheralsCoursesData && theData?.peripheralsCoursesData.map((course: any, index: number) => (
                            <Col span={6} key={index}>
                                <div className="bg-gray-100 h-[200px] p-[10px] rounded-lg">
                                    <img src={course?.banner} alt='' className="w-full h-24 object-cover rounded-lg" />
                                    <p className="mt-2 text-sm font-[400] line-clamp-4">{course?.name}</p>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </div>

                <hr className='my-[20px]' />

                {/* Grade Level */}
                <div className="mb-[20px]">
                    <h2 className="text-lg font-semibold mb-2">Grade Level</h2>
                    <p>{theData?.gradeLevelData?.name} ({getAgeRange(theData?.gradeLevelData?.ageGroup)} years)</p>
                </div>

                <hr className='my-[20px]' />

                {/* Grade Level */}
                <div className="mb-[20px]">
                    <h2 className="text-lg font-semibold mb-2">Description</h2>
                    <p>{theData?.description}</p>
                </div>

                {/* Cost, Duration, Dates */}
                <div className="flex justify-between items-center mb-6">
                    <div className="flex items-center">
                        <FaRegMoneyBillAlt className="mr-2 text-green-500" />
                        <p className="font-semibold">Cost: GHS {theData?.overrideCost || 0}</p>
                    </div>
                    <div className="flex items-center">
                        <FiClock className="mr-2 text-blue-500" />
                        <p className="font-semibold">Duration: {theData?.durationInDays || 0} days</p>
                    </div>
                </div>

                <hr className='my-[20px]' />

                {/* Dates */}
                <div className="flex justify-between items-center">
                    <div className="flex items-center">
                        <FiCalendar className="mr-2 text-gray-500" />
                        <p className="text-gray-500">Created: {moment(theData?.createdAt).format("Do MMM YYYY")}</p>
                    </div>
                    <div className="flex items-center">
                        <FiCalendar className="mr-2 text-gray-500" />
                        <p className="text-gray-500">Updated: {moment(theData?.updatedAt).format("Do MMM YYYY")}</p>
                    </div>
                </div>
            </Card>


            {
                openEdit && <EditRoboBundler isOpened={openEdit} handleClose={() => setOpenEdit(false)} bundlerData={theData} handleReFetch={handleReFetch} />
            }
        </>
    )
}

export default BundlerCard
