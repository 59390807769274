import React, { useState } from "react";
import { useForm } from "antd/es/form/Form";
import {
  Form,
  Input,
  message,
  notification,
  Progress,
  Spin,
  Upload,
  UploadProps,
} from "antd";
import TagSelectorComponent from "../../components/tagSelectorComponent";
import InstructorSelectorComponent from "../../components/instructorSelectorComponent";
import CourseCategorySelectorComponent from "../../components/courseCategorySelectorComponent";
import LanguageSelectorComponent from "../../components/languageSelectorComponent";
import BaseService from "../../../../helpers/baseServices";
import { global_variables } from "../../../../helpers/globalVariables";
import { IoImagesOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { PiCloudArrowUpLight } from "react-icons/pi";
import DescriptionInputComponent from "../../components/descriptionInputComponent";
import { useMainContext } from "../../../../contexts/mainContext";
import { GenerateCode } from "../../../../functions/generateID";
import TermSelector from "../../components/termSelector";
import CourseTypeSelector from "../../components/courseTypeSelector";
import { PlusOutlined } from "@ant-design/icons";

const CourseInformation = () => {
  const [form] = useForm();
  const navigate = useNavigate();
  const { setSingleCourseInfo } = useMainContext();
  const [isBusy, setIsBusy] = useState(false);
  const [coverImage, setCoverImage] = useState<any>("");
  const [imageLoading, setImageLoading] = useState(false);
  const [imgProgress, setImgProgress] = useState(25);

  //courseImages
  const [courseImages, setCourseImages] = useState<any[]>([
    { file: null, url: "" },
    { file: null, url: "" },
    { file: null, url: "" },
    { file: null, url: "" },
    { file: null, url: "" },
  ]);
  const [fileList, setFileList] = useState<any[]>([]);

  //handleImageChange
  const handleChange = ({ fileList }: any) => {
    // Limit file list to a maximum of 5 files
    if (fileList.length <= 5) {
      setFileList(fileList);
    } else {
      notification.warning({
        message: "Image Limit Exceeded",
        description: "You can only upload up to 5 images.",
      });
    }
  };

  //handleRemoveImage
  const handleRemoveImage = (file: any) => {
    setFileList((prevList) =>
      prevList.filter((item: any) => item?.uid !== file?.uid)
    );
  };

  //handleImagesGroupUpload
  const uploadImages = async (file: any, index: number) => {
    if (file) {
      try {
        const fileFormat = file.type.split("/")[1];
        const uid = file.uid;

        const imgRes: any = await BaseService.file_upload(
          `${global_variables.file_upload}?imagename=${uid}&storage=test&mimeType=${fileFormat}`,
          file
        );
        console.log(`courseImg${index + 1}`, imgRes);
        const newImages = [...courseImages];
        newImages[index] = { file, url: imgRes?.data?.url || "" };
        setCourseImages(newImages);
        return imgRes?.data?.url || "" ;
      } catch (error: any) {
        console.log("file uploading", error);
        message.error(
          error?.response?.data?.error ||
            "Error occured while upload cover image. Please retry"
        );
        setImageLoading(false);
        setIsBusy(false);
      }
    }
  };

  //handleImageUpload
  const handleImageUpload: UploadProps = {
    action: "",
    async onChange(info) {
      const file = info.file.originFileObj;
      if (file) {
        setImageLoading(true);
        try {
          const fileFormat = file.type.split("/")[1];
          const uid = file.uid;
          setImgProgress(65);

          const imgRes: any = await BaseService.file_upload(
            `${global_variables.file_upload}?imagename=${uid}&storage=test&mimeType=${fileFormat}`,
            file
          );
          console.log("imgRes", imgRes);
          setCoverImage(imgRes?.data?.url);
          setImgProgress(95);
          setImageLoading(false);
        } catch (error: any) {
          console.log("file uploading", error);
          message.error(
            error?.response?.data?.error ||
              "Error occured while upload cover image. Please retry"
          );
          setImageLoading(false);
        }
      }
    },
  };

  //handleOnSubmit
  const handleOnSubmit = async (formData:any) => {

    try {

        setIsBusy(true);
        //upload each course image
        const uploadPromises = fileList.map((file:any, index:number) => uploadImages(file?.originFileObj, index));
        const imgUrls = await Promise.all(uploadPromises);

        //validImageUrls
        const validImageUrls = imgUrls.filter((url) => url)

        //prep data
        let courseInfo = {
          banner: coverImage,
          name: formData?.title,
          subtitle: formData?.subtitle,
          images: validImageUrls,
          description: formData?.desc,
          languageIds: formData?.language.map((lang: any) => lang?.value),
          categoryIds: formData?.category.map((cat: any) => cat?.value),
          educatorIds: formData?.instructor.map((ins: any) => ins?.value),
          tags: formData?.tags.map((tg: any) => tg?.value),
          country: "65d4b85e9ae65ff72c90900e",
          codec: `RBC-` + GenerateCode(10, formData?.title),
          type: formData?.type,
          level: formData?.level,
          activityThemes: [],
          //productAssesories[] optional
        };

        console.log("data_to_post:", courseInfo);

        BaseService.post_api(global_variables.add_new_course, courseInfo)
          .then((response) => {
            console.log("newCourseRes:", response?.data);
            setSingleCourseInfo(response?.data?.payload);
            message.success("New course has been created successfully!");
            navigate(`/courses/${response?.data?.payload?._id}`);
          })
          .catch((error: any) => {
            console.log("newCourseErr:", error);
            message.error(
              error?.response?.data?.error ||
                "Error while createing course. Please try again"
            );
            setIsBusy(false);
          });
    } catch (error:any) {
        console.log("__newCourseErr:", error);
        notification.error({message: 'Course Creation Error!', description: error?.response?.data?.message || "An error occurred please retry."});
        setIsBusy(false);
    }

  };

  return (
    <>
      <div className="w-full bg-white rounded-[20px] shadow-sm px-[30px] py-[24px]">
        <div className="flex justify-between items-center">
          <p className="text-[21px] font-[600] text-text_primary">
            Course Information
          </p>

          <button
            className="px-[20px] py-[8px] rounded-[30px] bg-default_blue text-white flex gap-[5px] items-center"
            onClick={() => form.submit()}
            disabled={isBusy}
          >
            <PiCloudArrowUpLight className="h-[20px] w-[20px]" />
            <p>Save Course</p>
          </button>
        </div>
        <hr className="my-[10px] border-[#CCCCF8]" />

        <div className="w-full">
          <Spin spinning={isBusy}>
            <Form className="w-full" form={form} onFinish={handleOnSubmit}>
              <div className="grid grid-cols-5">
                <div className="col-span-1">
                  <p>
                    <span className="text-red-700">*</span> Cover Image
                  </p>
                </div>

                <div className="col-span-4">
                  <Form.Item
                    name="image"
                    rules={[
                      {
                        required: true,
                        message: "Please upload a cover image",
                      },
                    ]}
                  >
                    <div className="h-[160px] w-full mb-[20px]">
                      <Upload.Dragger
                        showUploadList={false}
                        {...handleImageUpload}
                        className="w-full relative"
                        style={{
                          borderColor: "#E0E0FB",
                          border: "none",
                          background: "white",
                          color: "#5353C2",
                        }}
                      >
                        <div className="w-full h-[160px] rounded-[5px] border-[2px] border-[#CCCCF8] border-dashed flex gap-2 items-center bg-[#F9F9FF] cursor-pointer">
                          {imageLoading ? (
                            <div className="w-full px-[40px]">
                              <Progress percent={imgProgress} showInfo />
                              <p className="animate-pulse">Uploading...</p>
                            </div>
                          ) : coverImage ? (
                            <div className="w-full">
                              <img
                                className="h-[160px] rounded-[5px] w-full object-cover"
                                src={coverImage}
                                alt=""
                              />
                            </div>
                          ) : (
                            <div className="w-full flex flex-col items-center justify-center text-text_primary">
                              <IoImagesOutline className="h-[50px] w-[50px]" />
                              <p>Click to upload cover image</p>
                            </div>
                          )}
                        </div>
                      </Upload.Dragger>
                    </div>
                  </Form.Item>
                  {/* <p className='text-[14px]'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Consequatur, aperiam cum laboriosam laudantium iure eius aut voluptatem dicta eligendi earum sint impedit iusto accusantium saepe.</p> */}
                </div>
              </div>

              <div className="grid grid-cols-5 mt-[20px]">
                <div className="col-span-1">
                  <p>
                    Course Images
                  </p>
                </div>

                <div className="col-span-4 flex gap-[5px] items-center">
                  <Upload
                    listType="picture-card"
                    fileList={fileList}
                    onChange={handleChange}
                    onRemove={handleRemoveImage}
                    multiple
                    beforeUpload={() => false} // Disable automatic upload
                  >
                    {fileList.length < 5 && (
                      <div>
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>Upload</div>
                      </div>
                    )}
                  </Upload>
                </div>
              </div>

              <div className="grid grid-cols-5 mt-[20px]">
                <div className="col-span-1">
                  <p>
                    <span className="text-red-700">*</span> Course Title
                  </p>
                </div>

                <div className="col-span-4">
                  <Form.Item
                    name="title"
                    rules={[
                      { required: true, message: "Please add course title" },
                    ]}
                  >
                    <Input className="h-[40px]" />
                  </Form.Item>
                </div>
              </div>

              <div className="grid grid-cols-5 mt-[10px]">
                <div className="col-span-1">
                  <p>
                    <span className="text-red-700">*</span> Sub Title
                  </p>
                </div>

                <div className="col-span-4">
                  <Form.Item
                    name="subtitle"
                    rules={[
                      {
                        required: true,
                        message: "Please add course sub title",
                      },
                    ]}
                  >
                    <Input className="h-[40px]" />
                  </Form.Item>
                </div>
              </div>

              <div className="grid grid-cols-5 mt-[10px]">
                <div className="col-span-1">
                  <p>
                    <span className="text-red-700">*</span> Description
                  </p>
                </div>

                <div className="col-span-4">
                  <Form.Item
                    name="desc"
                    rules={[
                      {
                        required: true,
                        message: "Please add course description",
                      },
                    ]}
                  >
                    <DescriptionInputComponent
                      onChange={(e) => console.log(e)}
                    />
                  </Form.Item>
                </div>
              </div>

              <div className="grid grid-cols-5 mt-[10px]">
                <div className="col-span-1">
                  <p>
                    <span className="text-red-700">*</span> Choose Term
                  </p>
                </div>

                <div className="col-span-4">
                  <Form.Item
                    name="level"
                    rules={[
                      { required: true, message: "Please select a term" },
                    ]}
                  >
                    <TermSelector />
                  </Form.Item>
                </div>
              </div>

              <div className="grid grid-cols-5 mt-[10px]">
                <div className="col-span-1">
                  <p>
                    <span className="text-red-700">*</span> Course Type
                  </p>
                </div>

                <div className="col-span-4">
                  <Form.Item
                    name="type"
                    rules={[
                      { required: true, message: "Please select a type" },
                    ]}
                  >
                    <CourseTypeSelector />
                  </Form.Item>
                </div>
              </div>

              <div className="grid grid-cols-5 mt-[10px]">
                <div className="col-span-1">
                  <p>
                    <span className="text-red-700">*</span> Language
                  </p>
                </div>

                <div className="col-span-4">
                  <Form.Item
                    name="language"
                    rules={[
                      {
                        required: true,
                        message: "Please select at least one language",
                      },
                    ]}
                  >
                    <LanguageSelectorComponent isMulti />
                  </Form.Item>
                </div>
              </div>

              <div className="grid grid-cols-5 mt-[10px]">
                <div className="col-span-1">
                  <p>
                    <span className="text-red-700">*</span> Category
                  </p>
                </div>

                <div className="col-span-4">
                  <Form.Item
                    name="category"
                    rules={[
                      {
                        required: true,
                        message: "Please select at least one category",
                      },
                    ]}
                  >
                    <CourseCategorySelectorComponent />
                  </Form.Item>
                </div>
              </div>

              <div className="grid grid-cols-5 mt-[10px]">
                <div className="col-span-1">
                  <p>
                    <span className="text-red-700">*</span> Course Instructor(s)
                  </p>
                </div>

                <div className="col-span-4">
                  <Form.Item
                    name="instructor"
                    rules={[
                      {
                        required: true,
                        message: "Please select at least one instructor",
                      },
                    ]}
                  >
                    <InstructorSelectorComponent />
                  </Form.Item>
                </div>
              </div>

              <div className="grid grid-cols-5 mt-[10px]">
                <div className="col-span-1">
                  <p>
                    <span className="text-red-700">*</span> Tags
                  </p>
                </div>

                <div className="col-span-4">
                  <Form.Item
                    name="tags"
                    rules={[
                      {
                        required: true,
                        message: "Please select at least one tag",
                      },
                    ]}
                  >
                    <TagSelectorComponent />
                  </Form.Item>
                </div>
              </div>
            </Form>

            <div className="w-full flex justify-end mt-[10px]">
              <button
                className="px-[20px] py-[8px] rounded-[30px] bg-default_blue text-white flex gap-[5px] items-center"
                onClick={() => form.submit()}
                disabled={isBusy}
              >
                <PiCloudArrowUpLight className="h-[20px] w-[20px]" />
                <p>Save Course</p>
              </button>
            </div>
          </Spin>
        </div>
      </div>
    </>
  );
};

export default CourseInformation;
