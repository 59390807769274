import React, { useState } from "react";
import {
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Progress,
  Spin,
  Upload,
  UploadProps,
} from "antd";
import GradeLevelSelectorComponent from "../../components/gradeLevelSelectorComponent";
import RoboCurriculumSelector from "../roboCurriculum/roboCurriculumSelector";
import { PiCloudArrowUpLight } from "react-icons/pi";
import CourseSelectorComponent from "../../../../components/shared/courseSelectorComponent";
import BaseService from "../../../../helpers/baseServices";
import { global_variables } from "../../../../helpers/globalVariables";
import { IoImagesOutline } from "react-icons/io5";
// import { useSearchParams } from 'react-router-dom';

interface modalProps {
  isOpened: boolean;
  handleClose: () => void;
  handleReFetch: () => void;
}

const CreateNewRoboBundler = ({
  isOpened,
  handleClose,
  handleReFetch,
}: modalProps) => {
  // const [searchParams] = useSearchParams();
  // const priceType = searchParams.get('type');
  const [form] = Form.useForm();
  const [isBusy, setIsBusy] = useState(false);
  const [coverImage, setCoverImage] = useState<any>("");
  const [imageLoading, setImageLoading] = useState(false);
  const [imgProgress, setImgProgress] = useState(25);

  //handleImageUpload
  const handleImageUpload: UploadProps = {
    action: "",
    async onChange(info) {
      const file = info.file.originFileObj;
      if (file) {
        setImageLoading(true);
        try {
          const fileFormat = file.type.split("/")[1];
          const uid = file.uid;
          setImgProgress(65);

          const imgRes: any = await BaseService.file_upload(
            `${global_variables.file_upload}?imagename=${uid}&storage=test&mimeType=${fileFormat}`,
            file
          );
          console.log("imgRes", imgRes);
          setCoverImage(imgRes?.data?.url);
          setImgProgress(95);
          setImageLoading(false);
        } catch (error: any) {
          console.log("file uploading", error);
          message.error(
            error?.response?.data?.error ||
              "Error occured while upload cover image. Please retry"
          );
          setImageLoading(false);
        }
      }
    },
  };

  //on form submit
  const onFinish = () => {
    form
      .validateFields()
      .then((formValues: any) => {
        let cooked_data: any = {
          name: formValues?.name,
          description: formValues?.description,
          images: [coverImage],
          electives: formValues?.electives.map((item: any) => item?.value),
          peripherals: formValues?.peripherals.map((item: any) => item?.value),
          peripheralsNumber: formValues?.peripherals.length,
          country: "65d4b85e9ae65ff72c90900e",
          gradeLevelId: formValues?.gradeLevelId?.value,
          roboCuriculumId: formValues?.roboCuriculumId?.value,
          salePrice: parseInt(formValues?.salePrice),
          allowedDiscount: parseInt(formValues?.allowedDiscount),
          durationInDays: parseInt(formValues?.durationInDays),
          //tags[]optional
        };

        console.log("formValues:", cooked_data);

        setIsBusy(true);

        BaseService.post_api(`/robocentre/robo-bundler`, cooked_data)
          .then((response: any) => {
            console.log("newRoboRes:", response?.data);
            message.success("Robo bundler was created successfully!");
            setIsBusy(false);
            form.resetFields();
            handleClose();
            handleReFetch();
          })
          .catch((error: any) => {
            console.log("newRoboErr:", error);
            message.error(
              error?.response?.data?.error ||
                "An unknown error occured. Please try again"
            );
            setIsBusy(false);
          });
      })
      .catch((error) => {
        console.log("formErrors:", error);
      });
  };

  return (
    <>
      <Modal
        title="Create New Bundler"
        open={isOpened}
        onCancel={handleClose}
        footer={null}
        keyboard={false}
        maskClosable={false}
        closeIcon={!isBusy}
        centered
      >
        <hr className="mb-[20px]" />
        <div className="h-[450px] w-full px-[20px] overflow-y-auto">
          <Spin spinning={isBusy}>
            <Form form={form} layout="vertical" onFinish={onFinish}>
              <Form.Item
                name="image"
                rules={[
                  { required: true, message: "Please upload a bundler image" },
                ]}
              >
                <div className="h-[160px] w-full mb-[20px]">
                  <Upload.Dragger
                    showUploadList={false}
                    {...handleImageUpload}
                    className="w-full relative"
                    style={{
                      borderColor: "#E0E0FB",
                      border: "none",
                      background: "white",
                      color: "#5353C2",
                    }}
                  >
                    <div className="w-full h-[160px] rounded-[5px] border-[2px] border-[#CCCCF8] border-dashed flex gap-2 items-center bg-[#F9F9FF] cursor-pointer">
                      {imageLoading ? (
                        <div className="w-full px-[40px]">
                          <Progress percent={imgProgress} showInfo />
                          <p className="animate-pulse">Uploading...</p>
                        </div>
                      ) : coverImage ? (
                        <div className="w-full">
                          <img
                            className="h-[160px] rounded-[5px] w-full object-cover"
                            src={coverImage}
                            alt=""
                          />
                        </div>
                      ) : (
                        <div className="w-full flex flex-col items-center justify-center text-text_primary">
                          <IoImagesOutline className="h-[50px] w-[50px]" />
                          <p>Click to upload bundler image</p>
                        </div>
                      )}
                    </div>
                  </Upload.Dragger>
                </div>
              </Form.Item>

              <Form.Item
                label="Bundler Name"
                name="name"
                rules={[
                  { required: true, message: "Please enter bundler name!" },
                ]}
              >
                <Input className="h-[40px]" placeholder="Ex: Robo Bundler" />
              </Form.Item>

              <Form.Item
                label="Description"
                name="description"
                rules={[
                  { required: true, message: "Please add course description" },
                ]}
              >
                <Input.TextArea rows={4} />
              </Form.Item>

              <Form.Item
                label="Robo Curriculum"
                name="roboCuriculumId"
                rules={[
                  { required: true, message: "Please select a curriculum!" },
                ]}
              >
                <RoboCurriculumSelector isMulti={false} />
              </Form.Item>

              <Form.Item
                label="Elective Courses"
                name="electives"
                rules={[
                  {
                    required: true,
                    message: "Please select an elective course!",
                  },
                ]}
              >
                <CourseSelectorComponent />
              </Form.Item>

              <Form.Item
                label="Addon Courses"
                name="peripherals"
                rules={[
                  { required: true, message: "Please select an addon course!" },
                ]}
              >
                <CourseSelectorComponent />
              </Form.Item>

              <Form.Item
                label="Grade Level"
                name="gradeLevelId"
                rules={[
                  { required: true, message: "Please select a grade level!" },
                ]}
              >
                <GradeLevelSelectorComponent isMulti={false} />
              </Form.Item>

              <Form.Item
                label="Sale Price"
                name="salePrice"
                rules={[
                  { required: true, message: "Please enter sale price!" },
                ]}
              >
                <InputNumber
                  className="h-[40px] w-full"
                  placeholder="Ex: 450"
                />
              </Form.Item>

              <Form.Item
                label="Allowed Discount (%)"
                name="allowedDiscount"
                rules={[
                  { required: true, message: "Please enter discount value!" },
                ]}
              >
                <InputNumber className="h-[40px] w-full" placeholder="Ex: 10" />
              </Form.Item>

              <Form.Item
                label="Duration in days"
                name="durationInDays"
                rules={[{ required: true, message: "Please enter duration!" }]}
              >
                <InputNumber
                  className="h-[40px] w-full"
                  placeholder="Ex: 450"
                />
              </Form.Item>

              <Form.Item>
                <button className="w-full h-[50px] rounded-[5px] bg-default_blue text-white flex gap-[5px] items-center justify-center">
                  <PiCloudArrowUpLight className="h-[20px] w-[20px]" />
                  <p>Publish Bundler</p>
                </button>
              </Form.Item>
            </Form>
          </Spin>
        </div>
      </Modal>
    </>
  );
};

export default CreateNewRoboBundler;
