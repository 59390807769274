import React, { useState } from 'react';
import { Tag, Tooltip } from 'antd';
import { FaSchool, FaCalendarAlt } from 'react-icons/fa';
import { HiOutlineClock } from 'react-icons/hi';
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import EditRoboCurriculum from './editRoboCurriculum';
import { useNavigate } from 'react-router-dom';


interface cardProps {
    handleReFetch: () => void
    theData: any
}


const RoboCurriculumCard = ({ theData, handleReFetch }: cardProps) => {

    const navigate = useNavigate()
    const [openEditModal, setOpenEditModal] = useState(false)


    return (
        <>
            <div className='flex gap-[10px]'>
                <div className="w-full bg-white shadow-sm border border-gray-300 rounded-[20px] p-[20px] text-text_primary cursor-pointer" onClick={() => navigate(`/courses/bundler/${theData?._id}`)}>
                    <div className='flex gap-[20px] justify-between items-center'>
                        <p className='text-[18px] font-[600]'>{theData?.title}</p>

                        {
                            theData?.mainCuriculum ?
                                <Tag className='rounded-[30px]' color="green">Main Curriculum</Tag>
                                :
                                <Tag className='rounded-[30px]' color="warning">Regular</Tag>
                        }
                    </div>

                    <hr className='my-[10px]' />

                    <div>
                        <div className='flex gap-[5px] items-center'>
                            <FaSchool className='h-4 w-4' />
                            <p className='font-[600] mt-1'>Grade Levels:</p>
                        </div>
                        <div className='flex gap-[5px] flex-wrap mt-2'>
                            {
                                theData?.gradeLevelsData.map((item: any, i: number) => (
                                    <Tag key={i} className='rounded-[30px]' color='blue'>{item?.name}</Tag>
                                ))
                            }
                        </div>
                    </div>

                    <div className="flex w-full justify-between items-center mt-[20px]">
                        <div>
                            <div className='flex gap-[5px] items-center'>
                                <FaCalendarAlt className='h-4 w-4' />
                                <p className='font-[600] mt-1'>Site:</p>
                            </div>

                            <p>{theData?.site === "FAST_PACED" ? "ONLINE" : theData?.site}</p>
                        </div>

                        <div className='text-right'>
                            <div className='flex gap-[5px] items-center'>
                                <HiOutlineClock className='h-4 w-4' />
                                <p className='font-[600] mt-1'>Duration:</p>
                            </div>

                            <p>{theData?.durationInDays} days</p>
                        </div>
                    </div>

                    {theData?.description ?
                        <div className='mt-[20px]' dangerouslySetInnerHTML={{ __html: theData?.description }}></div>
                        :
                        <div className='mt-[20px]'>
                            No description available
                        </div>
                    }

                    {
                        theData?.seasonal &&
                        <div className='mt-[20px] flex justify-between items-center gap-[20px]'>
                            <p><strong>Starts: </strong> {moment(theData?.seasonal?.startCuriculum).format("Do MMM YY")}</p>
                            <p><strong>Ends: </strong> {moment(theData?.seasonal?.endCuriculum).format("Do MMM YY")}</p>
                        </div>
                    }

                    <div className='mt-[10px] flex justify-between items-center gap-[20px]'>
                        <p><strong>Created At: </strong> {moment(theData?.createdAt).format("Do MMM YY")}</p>
                        <p><strong>Updated At: </strong> {moment(theData?.updatedAt).format("Do MMM YY")}</p>
                    </div>
                </div>

                {/* buttons */}
                <div className='mt-[10px]'>
                    <Tooltip title='Edit'>
                        <button onClick={() => setOpenEditModal(true)}>
                            <PencilSquareIcon className='h-5 w-5' />
                        </button>
                    </Tooltip>

                    <Tooltip title='Delete'>
                        <button className='mt-[10px]'>
                            <TrashIcon className='h-5 w-5 text-red-500' />
                        </button>
                    </Tooltip>
                </div>
            </div>



            {/* open edit Modal */}
            {
                openEditModal && <EditRoboCurriculum isOpened={openEditModal} handleClose={() => setOpenEditModal(false)} curriculumData={theData} handleReFetch={handleReFetch} />
            }
        </>
    );
};

export default RoboCurriculumCard;
